"use client";

import { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import { Logo } from "./components/decoration/Logo";
import ThemeSwitcher from "./components/ThemeSwitcher";
import { signOut } from "@/lib/auth";
import { classNames } from "@/lib/utils/cssHelpers";
import { User } from "lucia";
import { handleRedirectLoginPage as b } from "./user/actions";

const navigation = [
    // { name: "Dashboard", href: "/" },
    // { name: "Uploader", href: "/uploader" },
    // { name: "List", href: "/list" },
    { name: "Bildupload", href: "/entities" },
    { name: "Bilder verwalten", href: "/images" },
    //{ name: "Bildupload", href: "/newimage"}
];

export default function Navbar({
    user,
    pathname,
}: {
    user?: User;
    pathname: string;
}) {
    const signOut = () => {
        fetch("/api/signout", { method: "POST" }).then(() => {
            window.location.reload();
        });
    };

    const handleRedirectLoginPage = () => {};
    return (
        <Disclosure as="nav" className="bg-base-content h-16 w-full fixed z-10">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex flex-shrink-0 py-3">
                                    <Logo />
                                </div>
                                <div className="text-xl px-16 align-bottom sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className={classNames(
                                                String(pathname).match(
                                                    item.href
                                                ) != null
                                                    ? "border-white text-white"
                                                    : "border-transparent hover:border-gray-300",
                                                "text-white inline-flex items-end px-2 py-1 border-b-4 font-medium"
                                            )}
                                            aria-current={
                                                pathname === item.href
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                            <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">
                                            <span className="sr-only">
                                                Open user menu
                                            </span>
                                            <UserIcon className="icon fill-primary" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {user ? (
                                                <>
                                                    <Menu.Item>
                                                        <div className="px-4 py-2 label-text">
                                                            
                                                            <div className="truncate">{user.email}</div>
                                                            <div>{user.role}</div>
                                                    
                                                        </div>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                            <a
                                                                href="/user/settings"
                                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            >
                                                                Einstellungen
                                                            </a>
                                                    </Menu.Item>
                                                    {user.role == "admin" ? (
                                                        <Menu.Item>
                                                            <a
                                                                href="/user/admin"
                                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            >
                                                                Userverwaltung
                                                            </a>
                                                    </Menu.Item>
                                                    ): null}
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className={classNames(
                                                                    active
                                                                        ? "bg-gray-100"
                                                                        : "",
                                                                    "flex w-full px-4 py-2 text-sm text-gray-700"
                                                                )}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    signOut();
                                                                }}
                                                            >
                                                                Sign out
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </>
                                            ) : (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-100"
                                                                    : "",
                                                                "flex w-full px-4 py-2 text-sm text-gray-700"
                                                            )}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (
                                                                    pathname !=
                                                                    "/login"
                                                                ) {
                                                                    handleRedirectLoginPage();
                                                                }
                                                            }}
                                                        >
                                                            Sign in
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            )}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <div className="-mr-2 flex items-center sm:hidden">
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 pt-2 pb-3">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={classNames(
                                        pathname === item.href
                                            ? "bg-slate-50 border-slate-500 text-slate-700"
                                            : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                                        "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                    )}
                                    aria-current={
                                        pathname === item.href
                                            ? "page"
                                            : undefined
                                    }
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                        <div className="border-t border-gray-200 pt-4 pb-3">
                            {user ? (
                                <>
                                    <div className="flex items-center px-4">
                                        <div className="flex-shrink-0">
                                            {/* <Image
                                                className="h-8 w-8 rounded-full"
                                                src={user.image}
                                                height={32}
                                                width={32}
                                                alt={`${user.name} avatar`}
                                            /> */}
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">
                                                {String(user.name)} ({user.role}
                                                )
                                            </div>
                                            <div className="text-sm font-medium text-gray-500">
                                                {user.email}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 space-y-1">
                                        <button
                                            onClick={() => signOut()}
                                            className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                        >
                                            Sign out
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className="mt-3 space-y-1">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleRedirectLoginPage();
                                        }}
                                        className="flex w-full px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                    >
                                        Sign in
                                    </button>
                                </div>
                            )}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
